import React from "react";
import { Accordion } from "react-bootstrap";
import { Moduls } from "../../Moduls/Moduls";
import { String } from "../../Moduls/String";
import styles from "./SubServices.module.css";
interface SubServicesProps {
  addClassOnScrollServices: boolean;
}
export const SubServices: React.FC<SubServicesProps> = (props) => {
  const newData = String.whatWeDo.map((accortionData: Moduls.data, index) => {
    const accordionButton = (event: Event) => {
      const button = document.querySelector(".accordion-button") as HTMLElement;
      button.style.background = "red";
    };
    document.addEventListener("click", accordionButton);
    document.removeEventListener("click", accordionButton);
    return (
      <>
        <Accordion.Item eventKey={index.toString()}>
          <Accordion.Header className="accordion-itemTest ">
            {accortionData.title?.toUpperCase()}
          </Accordion.Header>
          <Accordion.Body>{accortionData.desciption}</Accordion.Body>
        </Accordion.Item>
      </>
    );
  });

  return (
    <>
      <div className={styles.contactFormWrapper}>
        {/* <div className={styles.mainTitile}><h2>{String.ourServices.title}</h2></div> */}
        <div className={`${styles.leftRightWrapper}`}>
          <div
            className={`${styles.left} ${
              props.addClassOnScrollServices
                ? "animate__animated animate__fadeInLeft"
                : ""
            }`}
          >
            <div className={styles.ImageWrapper}>
              <img src="./images/manPuttingParkettR.jpg" alt="" />
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.inquiry}>
              <h2>{String.ourServices.title}</h2>
              {/* <p>{String.inquiry.des}</p> */}
            </div>
            <Accordion className="text pl-4">{newData}</Accordion>
          </div>
        </div>
      </div>
    </>
  );
};
