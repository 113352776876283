import React, { useEffect, useState } from "react";
import { Toolbar } from "./toolBar/ToolBar";
import { NavBar } from "./NavBar";
import { Footer } from "./Footer/Footer";
import { Contact } from "./contact/contact";
import { MaiHome } from "./HomePage/MainHome";
import { Projects } from "./ourProject/Projects";
import { Impressum } from "./impressum/Imressum";
import { Helmet } from "react-helmet";

export const HomePage = () => {
  const [checkSectionVisibleImp, setcheckSectionVisibleImp] = useState(false);
  const [checkSectionVisibleServices, setcheckSectionVisibleServices] =
    useState(false);
  const [checkSectionVisibleContact, setcheckSectionVisibleContact] =
    useState(false);
  const [checkSectionVisibleImage, setcheckSectionVisibleImage] =
    useState(true);
  useEffect(() => {
    if (checkSectionVisibleImp) {
      setcheckSectionVisibleServices(false);
      setcheckSectionVisibleContact(false);
      setcheckSectionVisibleImage(false);
    }
  }, [checkSectionVisibleImp]);

  return (
    <>
      <Toolbar />
      <NavBar
        checkSectionVisibleImage={setcheckSectionVisibleImage}
        checkSectionVisibleImp={setcheckSectionVisibleImp}
        checkSectionVisibleDienstleistungen={setcheckSectionVisibleServices}
        checkSectionVisibleContact={setcheckSectionVisibleContact}
        isImpressumActive={checkSectionVisibleImp}
      />

      {checkSectionVisibleImage && !checkSectionVisibleImp && <MaiHome />}
      {checkSectionVisibleServices && !checkSectionVisibleImp && (
        <Projects
          MobileView={false}
          checkSectionVisibleServices={checkSectionVisibleServices}
        />
      )}
      {checkSectionVisibleContact && !checkSectionVisibleImp && <Contact />}
      {checkSectionVisibleImp && <Impressum />}
      <Footer checkSectionVisibleImp={setcheckSectionVisibleImp} />
    </>
  );
};
