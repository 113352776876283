import React, { useEffect, useState } from "react";

import styles from "./Projects.module.css";
import projectItemList from "./projects.json";
import projectCategoryList from "./projectCategory.json";
import jamboHeaderData from "./jamboHeaderData.json";
import { HeroImage } from "../HeroImage/HeroImage";
import { JamboText, JamboTextProps } from "../HomePage/HomeHeading/JamboText";
import { String } from "../Moduls/String";
interface ProjectProps {
  MobileView: boolean;
  checkSectionVisibleServices?: boolean;
}
interface projectDetail {
  name?: string;
  id?: string;

  created?: string;
  imagePath?: string;
  detail?: {
    dis?: string;
    photos?: {
      id?: string;
      imagePath?: string;
    };
  };
}
export const Projects: React.FC<ProjectProps> = (props) => {
  const [itemSelected, setItemSelected] = useState("all");
  const [isMouseOver, setIsMouseOver] = useState<string>();
  const [projectItemSelected, setDataSelected] = useState<projectDetail>();
  const [isProjectSelected, setIsProjectSelected] = useState(false);

  const projectListHandler = (id: string) => {
    setItemSelected(id);
  };
  const introJamboTex: JamboTextProps = {
    title: String.ourProjectInto.title,
    des: String.ourProjectInto.des,
  };
  const onMouseOverHandler = (id: string) => {
    if (id != "") setIsMouseOver(id);
    else {
      //to make the stat empty that the class not stay when mouse leave from image it will remove the background.
      setIsMouseOver("");
    }
  };
  const isProjectSelectedHandler = (selected: boolean) => {
    setIsProjectSelected(selected);
  };
  const selectedProject = (data: projectDetail) => {
    setDataSelected(data);
    isProjectSelectedHandler(true);
  };
  // newList will hold all the category list
  const newList = projectCategoryList.map((data, index) => {
    return (
      <div
        key={index}
        className={itemSelected === data.category ? styles.active : ""}
        onClick={() => projectListHandler(data.category)}
      >
        {data.name}
      </div>
    );
  });

  //after selecting the category, it will filter the list item
  const filterProjectItem = projectItemList.filter((filter) => {
    let itemData = "";
    if (itemSelected === filter.category && filter.category.length >= 1) {
      itemData = filter.category;
    } else if (itemSelected === "all") {
      itemData = filter.category;
    }

    return itemData;
  });
  //show filtered list item
  const mapData = filterProjectItem.map((data, index) => {
    return (
      <div
        key={index}
        onMouseLeave={() => onMouseOverHandler("")}
        onMouseOver={(e) => onMouseOverHandler(data.id)}
        className={`${styles.card} ${
          isMouseOver === data.id ? styles.isMouseOver : styles.isMouseLeave
        }`}
      >
        <div
          className={styles.imageWrapper}
          onClick={() => {
            selectedProject(data);
          }}
        >
          <img src={data.imagePath} />
          {data.name}
        </div>
      </div>
    );
  });
  return (
    <>
      <HeroImage
        url={"url(./images/fullViewParkittR.jpg)"}
        title={
          "Entdecken Sie unsere beeindruckende Sammlung außergewöhnlicher Projekte"
        }
        checkSectionVisibleServices={props.checkSectionVisibleServices}
      />
      <div className={styles.projectsItems}>
        {/* <div className={styles.projectIemsWrapper}>{newList}</div> */}
        <JamboText {...introJamboTex} />
        <div className={styles.cardWrapper}>{mapData}</div>
      </div>
    </>
  );
};
