import { HeaderImage } from "./HeaderImage/HeaderImage";
import { JamboText, JamboTextProps } from "./HomeHeading/JamboText";
import { SubServices } from "./SubServices/SubServices";
import { HomeFeature } from "./HomeFeature/Feature";
import { String } from "../Moduls/String";
import { ContectForm } from "../contact/contactForm";
import { useEffect, useState } from "react";

export const MaiHome = () => {
  const [addClassOnScrollServices, setAddClassOnScrollServices] =
    useState(false);
  const [
    addClassOnScrollServicesWithImages,
    setAddClassOnScrollServicesWithImages,
  ] = useState(false);

  // Function to handle scroll events
  const handleScroll = () => {
    if (window.scrollY > 1050 && window.scrollY < 1250) {
      // Adjust this threshold as needed
      setAddClassOnScrollServices(true);
    } else {
      setAddClassOnScrollServices(false);
    }
    if (window.scrollY > 1950 && window.scrollY < 2150) {
      // Adjust this threshold as needed
      setAddClassOnScrollServicesWithImages(true);
    } else {
      setAddClassOnScrollServicesWithImages(false);
    }
  };

  // Add or remove class on scroll
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const introJamboTex: JamboTextProps = {
    title: String.intojamoText.title,
    des: String.intojamoText.des,
  };
  const servicesJamboTex: JamboTextProps = {
    title: String.servicesJamoText.title,
    des: String.servicesJamoText.des,
  };

  return (
    <>
      <HeaderImage />
      <JamboText {...introJamboTex} />
      <SubServices addClassOnScrollServices={addClassOnScrollServices} />
      <JamboText {...servicesJamboTex} style={1} />
      <HomeFeature
        addClassOnScrollServicesWithImages={addClassOnScrollServicesWithImages}
      />
      <ContectForm />
    </>
  );
};
