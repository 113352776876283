import { Moduls } from "./Moduls";

export namespace String {
  export interface Newdata extends Idetail {
    data?: string;
    id?: string;
  }
  export interface Idetail {
    title?: string;
    Description?: string;
  }
  export enum ServicesData {
    title = "Services",
    Description = "Where Quality of work intersects trust",
  }
  export enum ContactHeaderDetail {
    title = "Kontakt aufnehmen",
    description = "",
    mailIcon = "fa fa-envelope",
    emaiTitle = "E-Mail",
    email = "office29.aim@gmail.com",
    companyName = "AIM -> Professionelle Parkett- und Laminatverlegung",
    address1 = `Kopalgasse 40/4, 1110 Wien`,
    PhoneTitle = "Telefon",
    mobile1 = "+43 676/433 22 00",
    website = "https://a-im.at/",
  }

  export enum FooterData {
    contactUs = "KONTAKTIEREN SIE UNS JETZT",
    term = "Bedingungen",
    services = "Dienstleistungen",
    powredBy = "AIM Power By | ZSOFTMEDIA",
    copyRight = "Copyright",
    termCondition = "IMPRESSUM | DATENSCHUTZ",
  }
  export enum AboutString {
    About = "About Us",
    AboutTitle = "Growth + Success + Efficiency + Profitability",
    AboutDes = "Black Book Accounting provides a full range of accounting and bookkeeping services geared towards assisting small to midsize companies. We give your business all the benefits of outsourced services such as vat registration and return filing , tax consultation , accounting consultation and auditing with our extensive experience and resources. That allows business owners like you to reduce time spent on non-essen-tial takes, enabling you to focus on core business function and profitability.",
  }
  export enum whyUs {
    WhyUSTitle = "Why black book accounting ",
    //    WhyUSDes =`Our team excelled their greater amount of passion, dedication and intensity towards
    //    their work. it is supported by learner of accounting professionals who are available to perform
    //    higher level functions on a part time or short time basis if needed.
    //    we believe that , by working collaboratively with clients, black book accounting people
    //    are ideally places to bring the best of their skills and teamwork to every engagement.
    //    `
    WhyUsDes = `Our team excelled their greater amount of passion, dedication and intensity towards 
        their work. it is supported by learner of accounting professionals who are available to perform 
        higher level functions on a part time or short time basis if needed.
        we believe that , by working collaboratively with clients, black book accounting people
        are ideally places to bring the best of their skills and teamwork to every engagement.
        `,
  }

  export const whatWeDo = [
    // {
    //   title: "Parkett Schleifen/ Versiegeln",
    //   desciption: `Parkett Schleifen/Versiegeln: Erneuern und schützen Sie Ihre Holzböden mit unseren Parkett Schleifen (Schleifen) und Versiegeln (Versiegeln) Dienstleistungen. Durch sorgfältiges Schleifen stellen wir die Oberfläche in ihrem früheren Glanz wieder her, gefolgt von einer fachmännischen Versiegelung, um ihre Schönheit für die kommenden Jahre zu bewahren. Verwandeln Sie Ihren Raum mit revitalisierter Eleganz`,
    // },
    // {
    //   title: `Parkett Verlegen`,
    //   desciption: `Parkett Verlegen: Setzen Sie auf Meisterhandwerk für die Verlegung Ihres Parkettbodens. Unsere Experten schaffen ein nahtloses und ästhetisch ansprechendes Ergebnis. Jeder Raum wird durch unser präzises Parkett Verlegen in eine Oase zeitloser Schönheit und natürlicher Eleganz verwandelt.`,
    // },
    // {
    //   title: `Parkett Kaufen`,
    //   desciption: `Parkett Kaufen: Entdecken Sie hochwertiges Parkett für Ihren Raum. Unsere Auswahl bietet vielfältige Stile und Qualitäten, um Ihren individuellen Geschmack und Anforderungen gerecht zu werden. Verleihen Sie Ihrem Zuhause einen Hauch von zeitloser Eleganz mit unserem Parkett Sortiment.`,
    // },
    // {
    //   title: `Parkett Pflege`,
    //   desciption: `Parkett Pflege: Gewährleisten Sie die langanhaltende Schönheit Ihres Parkettbodens durch angemessene Pflege. Unsere Parkett Pflege-Dienstleistungen bieten maßgeschneiderte Lösungen, um die natürliche Anziehungskraft Ihrer Böden zu bewahren und zu steigern. Erhalten Sie die Eleganz und Langlebigkeit Ihrer Investition durch unsere engagierte Pflege`,
    // },
    // {
    //   title: `Parkett Färben`,
    //   desciption: `Parkett Färben: Verwandeln Sie Ihren Parkettboden mit unserem Färbeservice. Unsere spezielle Behandlung verleiht Tiefe und Charakter, verstärkt die natürliche Schönheit des Holzes und verleiht Ihrem Raum eine einzigartige Note. Heben Sie Ihr Innendesign mit unserer Parkett Färben Lösung auf eine neue Ebene.`,
    // },
    // {
    //   title: `Parkett Ölen`,
    //   desciption: `Parkett Ölen: Verleihen Sie Ihrem Parkettboden natürlichen Glanz mit unserer Ölbehandlung. Unsere spezialisierte Pflege nährt das Holz, betont die Maserung und schützt gleichzeitig vor Abnutzung. Erwecken Sie die Schönheit und Widerstandsfähigkeit Ihres Bodens mit unserem Parkett Ölen Service`,
    // },
    // {
    //   title: `Parkett Reparatur`,
    //   desciption: `Parkett Reparatur: Bringen Sie die Schönheit Ihres Parkettbodens mit unserem Reparaturservice zurück. Unser erfahrenes Team behebt Schäden und Unvollkommenheiten, um ein nahtloses und erneuertes Erscheinungsbild zu gewährleisten. Bewahren Sie den Charme und die Integrität Ihres Bodens mit unserer Parkett Reparatur Lösung.`,
    // },
    {
      title: `Estrich vorbereitung`,
      desciption: `Fertigparkett ist eine leicht zu bewerkstelligende Angelegenheit,
      dessen Material auf den verschiedensten Unterböden verlegt werden kann. Wichtig
      dabei ist jedoch eine ebene, feste und trockene Grundlage.
      Ein hochwertiger Parkettboden ist logischer Anspruch vieler Haus- und Wohnungsbesitzer.
      Besonders beliebt: Der Fertigparkett. Ebene, feste und dauertrockene Unterböden sind die
      Grundvoraussetzung für eine fachmännische und erfolgreiche Verlegung von Fertigparkett.
      Die Ebenheit des Untergrundes sollte mit einem geraden Gegenstand, am besten mit einer
      Richtlatte und einem Messkeil kontrolliert werden. Alte Dielenböden sind nachzuschrauben,
      um eventuelles Knarren zu beseitigen.`,
    },
    {
      title: `Parkettboden verlegen`,
      desciption: `Mittels einer Zahnspachtel wird der Parkettkleber auf den
      Untergrund aufgetragen. Anschließend wird das Parkett direkt in das
      'Klebstoffbett' gelegt und an die anderen Parkettstäbe „herangeschoben“. Da liegen sie nun
      und warten, bis der Klebstoff abtrocknet.`,
    },
    {
      title: `Parkett bürsten`,
      desciption: `Unsere erfahrenen Parkettprofis arbeiten nach speziellen Bürstenverfahren
      und erzeugen mit verschiedenen Spezialbürsten eine markante Oberflächenstruktur.
      Zusätzlich können wir Ihren Parkett auch mit verschiedenen Kolorierungen gestalten.
      Verleihen Sie Ihrem alten Parkettboden einen neuen Glanz und eine neue Optik!`,
    },
    {
      title: `BODEN FÄRBEN / ENTFÄRBEN`,
      desciption: `Die individuelle farbliche Gestaltung eines Holzbodens
      ist einfach und schnell möglich. Im ersten Schritt wird eine Ölgrundierung aufgetragen, die
      aus zwei Komponenten sowie Pigmenten der jeweiligen Wunschfarbe gemixt werden. Im
      Anschluss daran folgt die Parkettversiegelung oder das Auftragen des Parkettöls. Die
      Farbpigmente werden durch den Lack oder`,
    },
    {
      title: `BODEN SCHLEIFEN`,
      desciption: `Bodenversiegelung bedeutet, dass der Boden luft- und wasserdicht
      abgedeckt wird, wodurch Regenwasser nicht oder nur unter erschwerten Bedingungen
      versickern kann. Auch der Gasaustausch des Bodens mit der ⁠Atmosphäre⁠ wird gehemmt.`,
    },
    {
      title: `EINZELNE DIELEN TAUSCHEN`,
      desciption: `Grundsätzlich funktioniert ein Austausch der
      Dielenbretter bei allen üblichen Parkettarten, es gibt jedoch gewisse Unterschiede bei der
      Entfernung der Dielen. Während bei einem schwimmend verlegten Parkettboden die Diele
      
      nach der „Behandlung“ mit der Tauchsäge relativ leicht herausgezogen werden kann, ist das
      bei verklebtem Parkett ein wenig aufwendiger.`,
    },
    {
      title: `WASSERSCHADEN REPARIEREN`,
      desciption: `Das für Parkett verwendete Material Holz ist ein
      natürlicher Rohstoff und damit auch anfällig für Wasser und Feuchtigkeit. Ein
      Wasserschaden ist daher besonders ärgerlich und kann langfristig erheblichen
      Schaden verursachen. Bei der Behebung eines solchen sind daher besondere Punkte zu
      beachten.BEI HELLEN WASSERSCHÄDEN AUF GEÖLTEM PARKETT KANN MIT
      PUNKTUELLEM SCHLEIFEN UND DER BEHANDLUNG MIT LEICHT DUNKLEREM
      ÖL AUSGEHOLFEN WERDEN.`,
    },
    {
      title: `Treppen mit Parkett verkleiden`,
      desciption: ` Eine Treppenverkleidung legt man typischerweise auf eine bestehende Rohbeton-Treppe. 
      Man kann Treppenstufen auf jegliche Art und Weise verkleiden. Beliebt sind aber heutzutage allen voran Beläge aus Holz (Parkett). 
      Dazu werden Stufen aus Parkett massgeschreinert, sodass sie die Betontritte satt einkleiden.`,
    },
    {
      title: `Teppichboden verlegen`,
      desciption: `Zur Fixierung des Teppichs verwendest du Teppichkleber . Der Teppichboden wird möglichst faltenfrei im
       Raum ausgelegt und an den Rändern grob und mit Überstand zugeschnitten. Der Feinschnitt erfolgt erst nach der Fixierung.`,
    },
    {
      title: `Laminatboden verlegen`,
      desciption: ` Laminat wird grundsätzlich schwimmend verlegt, das heißt, die Dielen werden nicht fest mit dem Untergrund verbunden,
       sondern sie liegen nur darauf – sie schwimmen sozusagen.`,
    },
    {
      title: `Vinyl/Designböden`,
      desciption: `Ist die Oberfläche aus dem Kunststoff Polyvinylchlorid (PVC) hergestellt, spricht man von einem Vinylboden.
       Der Vinylboden gehört also zu den Designböden, aber nicht jeder Designboden ist auch ein Vinylboden.`,
    },
  ];
  export enum companyDetail {
    name = "Aim",
    companyNumber = "",
    uid = "ATU78489217",
    strNumber = "08 458 / 9261",
    firmenbuchgerich = "Handelsgericht Wien",
    Kammer = "Wirschaftskammer Wien",
    anwendbaresBerufsrecht = "Gewerbeordnung 1994 (GewO), ",
    linRisBka = "https://www.ris.bka.gv.at/",
    Service = "Treppen mit Parkett verkleiden, Vinyl/Designböden, Laminatboden verlegen, Teppichboden verlegen, WASSERSCHADEN REPARIEREN, EINZELNE DIELEN TAUSCHEN, BODEN SCHLEIFEN, BODEN FÄRBEN / ENTFÄRBEN, Parkett bürsten, Parkettboden verlegen, Estrich vorbereitung",
    datenschutzerklärungAllgemeinesText = `Mit unserem Implementierten AIM legen wir als Unternehmen
    einen großen Wert auf Datenschutz. Wir sind durchgehend bemüht,
    Ihnen bestmöglichen Schutz und Sicherheit für Ihre übermittelten
    Daten zu bieten. Aus diesem Grund erfolgt sowohl die Bearbeitung
    als auch die Speicherung der personenbezogenen Daten
    ausschließlich nach der österreichischen und europäischen
    Datenschutzgrundverordnung (DSGVO). Als User (Nutzer) erklären Sie
    sich damit einverstanden, dass Ihre Daten verarbeitet werden
    können. Eine aktuelle Version der DSGVO finden Sie unter:`,
    datenschutzerklärungAllgemeinesLink = `https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE`,
    datenschutzerklärungAllgemeinesText2 = `Unsere Datenschutzerklärung bezieht sich nur auf den Inhalt unserer Homepage. 
  Sofern Sie einen unserer ausgehenden Links betätigst, informieren Sie sich bitte 
  sofort nach dem Weiterleiten auf dieser Seite, wie dort mit Ihren Daten umgegangen wird.
   Die Vereinbarung Ihrer personenbezogenen Daten (z.B. Anrede, Titel, Name, Adresse, Mail, 
    Telefon, Bankverbindung) erfolgt auf Basis des österreichischen Datenschutzrechtes.
     Nachstehend erklären wir Ihnen die Art, den Umgang und den Zweck der Erhebung, 
     der Verarbeitung und Nutzung Ihrer persönlichen Daten.`,
    datenschutzerklärungAllgemeinesText3 = `Wir machten Sie darauf aufmerksam, 
     dass eine Datenübertragung im Internet (z.B. eMail-Korrespondenz) keinen 100 % Schutz 
     vor dem Zugriff Dritter bieten kann. Im Rahmen einer Anfrage oder einer Beauftragung, 
     überprüfen wir Ihre übermittelten Daten auf Gültigkeit und Aktualität. 
     Egal ob Ihre Daten manuell oder automatisiert eingegeben worden sind. 
     Bei etwaigen Abweichungen werden diese Informationen ggf. geändert.`,
    datenschutzerklärungAllgemeinesText4 = `Natürlich besteht jederzeit die Möglichkeit, dass wir Sie kontaktieren,
      um eventuell etwas abzugleichen. Dies kann sowohl per Telefon, als auch per Mail erfolgen. 
      Alle übermittelten Daten, finden natürlich nur im Zuge der Zusammenarbeit Verwendung. 
      Eine Weitergabe der Daten an Dritte erfolgt nicht.    Ihre persönlichen Daten werden gelöscht, sofern der bestehende Vertrag nicht mehr aufrecht ist und keine gesetzliche Pflicht zur Speicherung der Informationen mehr besteht.`,
    datenschutzerklärungAllgemeinesText5 = `Ihre persönlichen Daten werden gelöscht, sofern der bestehende Vertrag 
      nicht mehr aufrecht ist und keine gesetzliche Pflicht zur Speicherung der Informationen mehr 
      besteht.`,
    Kontaktformular = `Im Zuge Ihrer Kontaktaufnahme, speichern wir alle notwendigen Daten für 12 Monate, um diese bearbeiten zu können. Ohne Ihrem Einverständnis verlassen diese aber natürlich nicht unser Unternehmen.`,
  }
  //Home we are the best agency
  export const FAQ = [
    {
      title: "WHAT IS AN AUDIT?",
      desciption:
        "The objective of an audit is to allow the auditors to form an opinion on the financial statements of a company.  To reach an audit opinion, the auditors will carry out procedures to obtain audit evidence to provide them with reasonable assurance that the financial statements are free of material misstatement, and will ensure that the financial statements have been prepared according to relevant legislation and accounting standards.",
    },
    {
      title: "WHICH COMPANIES NEED TO BE AUDITED?",
      desciption:
        "A company’s annual accounts for a financial year must be audited unless the company is exempt from an audit by meeting specific financial thresholds, or is exempt from the audit as a subsidiary company with an EEA parent who guarantees the subsidiary and; makes this guarantee and their own consolidated financial statements available on public record in the UK, or is exempt from the requirements as a non-profit making company subject to a public sector audit.",
    },
    {
      title: "OBJECTIVE AND INDEPENDENT",
      desciption:
        "Meer & Co strives to adhere to and stay in line with all regulations and standards of our professional body ICAEW.  We are objective and independent and our work reflects the highest level of professional quality and service. Meer & Co works with clients and their legal advisors as required to ensure the valuation delivered is fit for purpose and provides the end client with value for money.",
    },
    {
      title:
        "WHAT IF MY COMPANY IS EXEMPT FROM AUDIT AND SHAREHOLDERS ASK FOR AN AUDIT?",
      desciption:
        "Even if your company is usually exempt from an audit, you must get your accounts audited if shareholders who own at least 10% of shares (by number or value) ask you to. This can be an individual shareholder or a group of shareholders.  They must make the request in writing and send it to the company’s registered office address.  The request must arrive at least one month before the end of the financial year that the audit is being asked for.",
    },
    {
      title: "HOW CAN A COMPANY MAKE AN AUDIT COST-EFFECTIVE?",
      desciption:
        "There are a number of things that can be done to ensure that an audit runs as efficiently as possible. Make sure that all accounting staff are available during the time that the auditors are on-site. mCooperate with auditors planning schedule and all the information requested by the auditors should be provided on timely basis.",
    },
    {
      title: "WHAT DOCUMENTS MAY A COMPANY AUDITOR REQUIRE?",
      desciption: `Below is a summary of some of the main documents to be prepared in advance of the audit. If the documents are all readily available, disruption to your staff members during the audit will be minimised:
    
            All primary accounting records
            Diagram showing the structure &amp; ownership of the company/ group of companies
            Year-end bank reconciliation &amp; statements available for the entire period for all bank
            accounts
            Breakdown of all balance sheet amounts with invoices to evidence items such as fixed asset
            additions, prepayments &amp; accruals
            Aged debtors’ and creditors’ listing
            Wages records and P11D returns
            Stock reports where applicable
            All hire purchase and leasing agreements
            VAT returns and workings
            Copies of all meeting minutes held during the year and details of any changes in share
            ownership.`,
    },
  ];

  //Services
  export const services: Moduls.CardWithImageProps[] = [
    {
      title: "Parkettverlegung",
      des: `Professionelle Parkettverlegung, Sanierung, Behandlung. Hochwertiges Parkett von Top-Herstellern. Kontaktieren Sie uns jetzt für Ihren Traumboden!`,
      imagePath: "./images/floorWorkWithoutman2R.jpg",
    },
    {
      title: "Holzterrassen",
      des: `Entdecken Sie Holz in seiner zeitlosen Pracht. Unsere Expertise liegt in Terrassenlösungen: hochwertige Dielen, Zubehör und professionelle Planung für Ihre Traum-Outdoor-Oase.`,
      imagePath: "./images/floorTrace1RsizeServices.jpg",
    },
    {
      title: "Treppen & Stiegen",
      des: `Für die perfekte Raumgestaltung fertigen wir Holztreppen, die nahtlos zu Ihrem Parkett oder Holzboden passen. Harmonie und stilvolles Design sind unser Ziel.`,
      imagePath: "./images/stiegecopy.jpg",
    },

    {
      title: "Sanierung",
      des: `Ein Holzboden bleibt länger schön, aber wenn nötig, ist die Renovierung eine kostengünstige Option für frischen Glanz.`,
      imagePath: "./images/floorLivingRoom1.jpg",
    },
  ];
  export const intojamoText = {
    title: "AIM: Achtsamkeit / Individualität / Makellos",
    des: `Willkommen in einer Welt der Bodenexzellenz. Unsere Leidenschaft ist 
            Ihr perfekter Boden. Entdecken Sie die Kunst, Räume mit AIM auf ein neues 
            Niveau zu heben. Treten Sie ein 
            und enthüllen Sie ein Mosaik aus Bodenhandwerk, Schönheit und Innovation`,
  };
  export const ourProjectInto = {
    title: "Meisterhafte Verwandlungen: Unsere Projekte im Fokus",
    des: `Entdecken Sie Unsere Projekte
    Treten Sie in eine Welt ein, in der Holzböden ihre Strahlkraft zurückerlangen, Treppen neues Leben einhauchen und Oberflächen in voller Pracht erstrahlen. Unsere Projektsektion präsentiert die Kunst des Parkett Versiegelns, Parkett Ölens, des Schleifens und Ölens von Holztreppen, der Oberflächenveredelung sowie der Bodensanierung nach Wasserschaden. Erkunden Sie, wie wir Visionen zur Realität werden lassen und Räume schaffen, die Eleganz, Innovation und zeitlose Schönheit verkörpern.`,
  };
  export const servicesJamoText = {
    title: "AIM: Zeitlose Eleganz bei der Restaurierung von Holzböden",
    des: `AIM zeichnet sich in der Restaurierung von Holzböden aus und belebt gealterte Böden mit ihrer 
    außergewöhnlichen Expertise wieder zum Leben. Sie erwecken die natürliche Schönheit erneut und bieten
     eine kostengünstige Alternative zu neuen Fußböden, während sie Räume revitalisieren und Nostalgie hervorrufen.
      Nehmen Sie AIMs Engagement für zeitlose Eleganz an, wo Geschichte und Erneuerung miteinander verschmelzen.`,
  };
  export const inquiry = {
    title: "Kostenlose Anfrage für Parkett- und Bodenlösungen",
    subTitle: "Zögern Sie nicht, uns zu kontaktieren!",
    des: `Haben Sie Fragen oder benötigen Sie weitere Informationen zu unseren Parkett- und Bodenlösungen? 
      Kontaktieren Sie uns ganz einfach über das Kontaktformular auf unserer Website. 
      Sie erreichen uns auch telefonisch unter +43 676/433 22 00 oder per E-Mail an office29.aim@gmail.com. 
      Wir freuen uns, Ihnen weiterzuhelfen und Ihre Anfrage schnellstmöglich zu bearbeiten!`,
  };

  export const ourServices = {
    title: "Unsere Dienstleistungen",
  };
}
