import React, { useState } from "react";
import "./HeaderImage.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css";
export const HeaderImage = () => {
  const [sliderHeight, setSliderHeight] = useState(true);
  enum Slider1Data1 {
    slider = "./images/slider1.jpg",
    description = "Präzise Bodenlösungen: Professionelle Parkett- und Laminatverlegung.",
  }
  enum Slider1Data2 {
    slider = "./images/slider2.jpg",
    description = "Ihre Böden, Unsere Handwerkskunst: Professionelle Parkett- und Laminatverlegungen.",
  }
  enum Slider1Data3 {
    slider = "./images/slider3.jpg",
    description = "Von der Vision zur Realität: Fachmännisch verlegte Parkett- und Laminatböden ",
  }
  window.addEventListener("resize", () => {
    if (window.innerWidth < 900) {
      setSliderHeight(false);
    }
  });
  return (
    <>
      <div
        id="carouselExampleControls"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src={Slider1Data1.slider}
              className="d-block w-100 animate__animated animate__jackInTheBox"
              alt="..."
            />
            <div className="carousel-caption d-none d-md-block">
              <div className="carousel-caption d-none d-md-block carousel-fade animate__animated animate__bounceInLeft">
                <h2 className="mb-4 bgColor">{Slider1Data1.description}</h2>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={Slider1Data2.slider}
              className="d-block w-100 animate__animated animate__jackInTheBox"
              alt="..."
            />
            <div className="carousel-caption d-none d-md-block">
              <div className="carousel-caption d-none d-md-block carousel-fade animate__animated animate__bounceInLeft">
                <h2 className="mb-4 bgColor">{Slider1Data2.description}</h2>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={Slider1Data3.slider}
              className="d-block w-100 animate__animated animate__jackInTheBox"
              alt="..."
            />
            <div className="carousel-caption d-none d-md-block">
              <div className="carousel-caption d-none d-md-block carousel-fade animate__animated animate__bounceInLeft">
                <h2 className="mb-4 bgColor">{Slider1Data3.description}</h2>
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
};
