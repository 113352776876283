import React from "react";
import "./HeroImage.css";
interface HeroImageProps {
  title: string;
  url?: string;
  description?: string;
  checkSectionVisibleServices?: boolean;
}
export const HeroImage: React.FC<HeroImageProps> = (props) => {
  return (
    <>
      <div
        style={{ backgroundImage: props.url }}
        className="jumbotron heroImage bg-cover text-white imageOverLay"
      >
        <div
          className={`${
            props.checkSectionVisibleServices &&
            "aanimate__animated animate__bounceInLeft"
          } headerContainerText text-center`}
        >
          {/* <h1 className="display-4 font-weight-bold">{props.title}</h1> */}
          <h2 className=" bgColor">{props.title}</h2>
        </div>
      </div>
    </>
  );
};
