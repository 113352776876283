import React, { useState } from "react";
import { Logo } from "./Logo/Logo";
import "corenav/coreNavigation.css";

interface NavBardata {
  title: string;
  link: string;
}
const NAV = [
  { title: "HOME", link: "index" },
  { title: "DIENSTLEISTUNGEN", link: "Dienstleistungen" },
  { title: "KONTAKT", link: "contactus" },
];
interface checkSection {
  checkSectionVisibleImp: React.Dispatch<React.SetStateAction<boolean>>;
  checkSectionVisibleDienstleistungen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  checkSectionVisibleContact: React.Dispatch<React.SetStateAction<boolean>>;
  checkSectionVisibleImage: React.Dispatch<React.SetStateAction<boolean>>;
  isImpressumActive?: boolean;
}
export const NavBar: React.FC<checkSection> = (props) => {
  const [activeClass, setActive] = useState("HOME");
  const [isMenuIconChange, setisMenuIconChange] = useState(true);
  const menuIconeHandler = () => {
    setisMenuIconChange(!isMenuIconChange);
  };
  const setActiveClase = (active: string) => {
    setActive(active);
    switch (active) {
      case "HOME": {
        // setSectionLink("home")
        props.checkSectionVisibleImage(true);
        props.checkSectionVisibleImp(false);
        props.checkSectionVisibleDienstleistungen(false);
        props.checkSectionVisibleContact(false);
        break;
      }
      case "DIENSTLEISTUNGEN": {
        props.checkSectionVisibleImp(false);
        props.checkSectionVisibleDienstleistungen(true);
        props.checkSectionVisibleContact(false);
        props.checkSectionVisibleImage(false);
        // setSectionLink("about")
        break;
      }
      case "KONTAKT": {
        props.checkSectionVisibleImp(false);
        props.checkSectionVisibleDienstleistungen(false);
        props.checkSectionVisibleContact(true);
        props.checkSectionVisibleImage(false);
        break; // setSectionLink("contactus")
      }
    }
  };
  const navBarData = NAV.map((data: NavBardata) => {
    return (
      <li
        className={
          !props.isImpressumActive && activeClass === data.title
            ? "nav-item active"
            : "nav-item "
        }
        onClick={() => setActiveClase(data.title)}
      >
        <a href={"#" + data.link} className="nav-link">
          {data.title}
        </a>
      </li>
    );
  });
  return (
    <>
      <nav
        className="navbar navbar-expand-lg  ftco_navbar  ftco-navbar-light"
        id="ftco-navbar"
        style={{ backgroundColor: "#f5f5f5" }}
      >
        <div className="container">
          <div className="navbar-brand" onClick={() => setActiveClase("HOME")}>
            <Logo />
          </div>
          <button
            onClick={() => menuIconeHandler()}
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#ftco-nav"
            aria-controls="ftco-nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span
              className={isMenuIconChange ? "fa fa-bars" : "fa-solid fa-xmark"}
            ></span>
          </button>
          <div className="collapse navbar-collapse" id="ftco-nav">
            <ul className="navbar-nav m-auto">{navBarData}</ul>
          </div>
        </div>
      </nav>
    </>
  );
};
