import React from "react";
import "./contact.css";
import { String } from "../Moduls/String";
import { HeroImage } from "../HeroImage/HeroImage";
enum ContactHeaderImageText {
  title = "Wir lieben es, Ihnen zu helfen!",
  description = "Kontaktieren Sie uns für außergewöhnliche Bodendienstleistungen und Fachkompetenz.",
}
export const Contact: React.FC<any> = (props) => {
  return (
    <>
      <section>
        <HeroImage
          url={"url(./images/slider1.jpg"}
          title={ContactHeaderImageText.title}
          description={ContactHeaderImageText.description}
        />
      </section>
      {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css" integrity="sha256-mmgLkCYLUQbXn0B1SRqzHar6dCnv9oZFPEC1g1cwlkk=" crossorigin="anonymous" /> */}
      <section className="section gra-bgy" id="contactus">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-title-Services">
                <h2>{String.ContactHeaderDetail.title}</h2>
                <p>{String.ContactHeaderDetail.description}</p>
              </div>
            </div>
          </div>
          <div className="row flex-row-reverse">
            <div className="col-md-7 col-lg-8 m-15px-tb">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d2660.44430496642!2d16.41601257675843!3d48.17879034829059!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e0!4m0!4m5!1s0x476da9ff588d4373%3A0xd5481437502fbfff!2sKopalgasse%2040%2F4%2C%201110%20Wien!3m2!1d48.1787868!2d16.4185875!5e0!3m2!1sde!2sat!4v1693416143380!5m2!1sde!2sat"
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>

            <div className="col-md-5 col-lg-4 m-15px-tb">
              <div className="contact-name">
                <h5>{String.ContactHeaderDetail.companyName}</h5>
                <p>{String.ContactHeaderDetail.address1}</p>
                <p>{String.ContactHeaderDetail.mobile1}</p>
              </div>
              <div className="contact-name">
                <p>{String.ContactHeaderDetail.address1}</p>
              </div>
              <div className="">
                <a href="https://a-im.at/">
                  {String.ContactHeaderDetail.website}
                </a>
                <p>{String.ContactHeaderDetail.email}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
