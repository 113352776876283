import React, { useState } from "react";
import { String } from "../../Moduls/String";
import { Moduls } from "../../Moduls/Moduls";
import "@fortawesome/fontawesome-svg-core";
import styles from "./feature.module.css";
import { CardWithImage } from "../../Card/CardWithImage";
interface HomeFeatureProps {
  addClassOnScrollServicesWithImages: boolean;
}
export const HomeFeature: React.FC<HomeFeatureProps> = (props) => {
  const [description, setDescription] = useState("");

  const cardsData: Moduls.CardWithImageProps = {
    imagePath: "",
    title: "Test",
    des: "this is just for the test",
  };

  const servicesList = String.services.map(
    (servicesList: Moduls.CardWithImageProps) => {
      return (
        <CardWithImage
          imagePath={servicesList.imagePath}
          title={servicesList.title}
          des={servicesList.des}
          isReadMoreButttonVisible={false}
          addClassOnScrollServicesWithImages={
            props.addClassOnScrollServicesWithImages
          }
        />
      );
    },
  );
  return <div className={styles.cardBox}>{servicesList}</div>;
};
