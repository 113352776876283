import React from "react";
import style from "./imressum.module.css";
import { String } from "../Moduls/String";
interface TextContainerProps {
  heading: string;
  headingTitle: string;
  text: string;
}
export const Impressum: React.FC = () => {
  return (
    <div className={style.impressum_wrapper}>
      <div className={style.impressum_wrapper_left}>
        <div className={style.text_wrapper}>
          <div className={style.tex_heading}>
            <h1>IMPRESSUM</h1>
          </div>
          <div className={style.text}>
            <h3>{String.companyDetail.name}</h3>
            <span>{String.ContactHeaderDetail.address1}</span>
            <br />
            <span>Tel: </span>
            <br />
            <span>
              <a href={String.ContactHeaderDetail.website}>
                {" "}
                Web: {String.ContactHeaderDetail.website}
              </a>
            </span>
            <br />
            <span>Email: {String.ContactHeaderDetail.email}</span>
            <br />
            <h3>UID: </h3>
            <span>{String.companyDetail.uid}</span>
            <h3>Firmenbuchgericht: </h3>
            <span>{String.companyDetail.firmenbuchgerich} </span>
            <h3>Kammer: </h3>
            <span>{String.companyDetail.Kammer}</span>
          </div>
        </div>
        <div>
          <h3>Anwendbares Berufsrecht: </h3>
          <span>
            {String.companyDetail.anwendbaresBerufsrecht}
            <a href={String.companyDetail.linRisBka}>
              {String.companyDetail.linRisBka}
            </a>
          </span>
        </div>

        <div>
          <h3>Unternehmensgegenstand: </h3>
          <span>{String.companyDetail.Service.toLocaleLowerCase()}</span>
        </div>
      </div>
      <div className={style.impressum_wrapper_right}>
        <div className={style.text_wrapper}>
          <div className={style.tex_heading}>
            <h1>DATENSCHUTZ</h1>
            <h3>Datenschutzerklärung Allgemeines</h3>
            <p>
              {String.companyDetail.datenschutzerklärungAllgemeinesText}
              <a
                href={String.companyDetail.datenschutzerklärungAllgemeinesLink}
              >
                {" "}
                legal-content
              </a>
            </p>
            <p>{String.companyDetail.datenschutzerklärungAllgemeinesText2}</p>
            <p>{String.companyDetail.datenschutzerklärungAllgemeinesText3}</p>
            <p>{String.companyDetail.datenschutzerklärungAllgemeinesText4}</p>
            <p>{String.companyDetail.datenschutzerklärungAllgemeinesText5}</p>
          </div>
          <div>
            <h3>Kontaktformular</h3>
            <p>{String.companyDetail.Kontaktformular}</p>
          </div>
          <div>
            <h3>Webanalyse</h3>
            <p>
              Auf unserer Homepage verwenden wir die Dienste des
              Webanalysedienstes Google Analytics über den Google tag manager.
              Anbieter ist die Google Inc., 1600 Amphitheatre Parkway Mountain
              View, CA 94043, USA. Google Analytics verwendet solche
              zielorientierten Cookies.
            </p>
            <p>
              Ausführliche Informationen zur Nutzung und der Verwendung Ihrer
              Nutzerdaten seitens google analytics finden Sie unter Ihrer
              Datenschutzerklärung:
              <br />
              <a href="https://support.google.com/analytics/answer/6004245?hl=de">
                https://support.google.com/analytics/answer/6004245?hl=de
              </a>
            </p>
            <p>
              Sollten Sie mit der Verarbeitung bzw. Verwendung Ihrer
              persönlichen Daten nicht einverstanden sein, können Sie dies
              jederzeit unterbinden, indem Sie folgendes plugin herunterladen
              und installieren:
              <br />
              <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
                https://tools.google.com/dlpage/gaoptout?hl=de
              </a>
            </p>
            <p>
              Mit dieser Installation wird Ihre IP-Adresse erfasst werden, aber
              sofort (z.B. durch Löschung der letzten 8bit) pseudonymisiert.{" "}
            </p>
            <p>
              Zukünftig können Sie das Erfassen Ihrer Daten durch google
              analytics aufhalten, wenn Sie auf erscheinenden Link drücken.
              Dieses opt-out-cookie gibt Ihnen die Möglichkeit google analytics
              zu deaktivieren.
            </p>
            <p>
              Zusätzlich zum google analytics tool, nutzen wir die Erweiterung
              „anonymizeIP()“, damit alle IP-Adressen nur in der kurzen Fassung
              weiterverarbeiten werden können. Damit werden jegliche direkten
              Informationen ausgeschlossen.
            </p>
          </div>
          <div>
            <h3>Server-Log-Daten</h3>
            <p>
              Vertrauliche Daten brauchen einen besonderen Schutz. Die
              Übertragung Ihrer Daten erfolgt deshalb in einer
              SSL-Verschlüsselung.
            </p>
          </div>
          <div>
            <h3>Auskunft</h3>
            <p>
              Gemäß der EU-DSGVO haben Sie das Recht, unentgeltliche Auskunft
              über Ihre gespeicherten Daten zu erlangen. Ebenso haben Sie einen
              Anspruch diese zu berichtigen, zu sperren oder zu löschen. Wenden
              Sie sich diesbezüglich an unser Team:
              <a href={String.ContactHeaderDetail.email}>
                {String.ContactHeaderDetail.email}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
