import React, { FC } from "react";
import { String } from "../../Moduls/String";
import styles from "./JamboText.module.css";
export interface JamboTextProps {
  title?: string;
  des?: string;
  style?: number;
}
export const JamboText: React.FC<JamboTextProps> = (props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrappertext}>
        <h2> {props.title}</h2>
        <p>{props.des}</p>
      </div>
    </div>
  );
};
