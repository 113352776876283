import React from "react";
import { String } from "../Moduls/String";
import { Moduls } from "../Moduls/Moduls";
import styles from "./ToolBar.module.css";
const socialMedia = [
  {
    title: "Facebook",
    icon: "fa fa-facebook",
    link: "https://www.facebook.com/",
  },
  {
    title: "Tiktok",
    icon: "fa-brands fa-tiktok",
    link: "https://www.tiktok.com/@office29.aim?_t=8gZ8vQlpBhp&_r=1",
  },
  {
    title: "instogram",
    icon: "fa-brands fa-instagram",
    link: "#",
  },
];

export const Toolbar = () => {
  const socialMediaData = socialMedia.map(
    (socialMediaData: Moduls.SocialMedia) => {
      return (
        <a
          href={socialMediaData.link}
          className="d-flex align-items-center justify-content-center mb-0 phone pl-md-2"
        >
          <span className={socialMediaData.icon}></span>
        </a>
      );
    },
  );
  return (
    <div className="wrap " style={{ backgroundColor: "#f5f5f5" }}>
      <div className="container">
        <div className="bg-wrap">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center">
              <p className="mb-0 phone pl-md-2">
                <a
                  href="#"
                  className="mr-2"
                  style={{ color: "#ffff", fontSize: "16px" }}
                >
                  <span className="fa fa-phone mr-1"></span>{" "}
                  {String.ContactHeaderDetail.mobile1}
                </a>
                <a href="#" style={{ color: "#ffff", fontSize: "16px" }}>
                  <span className="fa fa-paper-plane mr-1"></span>{" "}
                  {String.ContactHeaderDetail.email}
                </a>
              </p>
            </div>
            <div
              className={`${styles.socialMedia} col-md-6 d-flex justify-content-md-end`}
              style={{ color: "#ffff", fontSize: "16px" }}
            >
              <div className="social-media">
                <p className="mb-0 phone pl-md-2">
                  {String.ContactHeaderDetail.address1}
                </p>
              </div>
              <div className={`${styles.socialMedia}`}>{socialMediaData}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
