import React, { ChangeEvent, useEffect, useState } from "react";
import styles from "./contactForm.module.css";
import { String } from "../Moduls/String";
import { Helmet } from "react-helmet";
interface FormProps {
  name: string;
  email: string;
}

export const ContectForm = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isNameValidate, setIsNameValidate] = useState(false);
  const [isEmailValidate, setIsEmailValidate] = useState(false);
  const contactDetails: FormProps = {
    name: "Name",
    email: "Email",
  };
  const formString = {
    labelName: "Name",
    labelEmail: "Email",
    labelContacNumber: "Kontaktnummer",
    validationEmail: "Bitte geben Sie Ihren vollständigen email ein.",
    validationName: "Bitte geben Sie Ihren vollständigen Namen ein.",
  };

  const onChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    name: string,
  ) => {
    switch (name) {
      case contactDetails.name: {
        if (event.target.value.length !== 0) {
          setIsNameValidate(true);
        } else {
          setIsNameValidate(false);
        }
        break;
      }
      case contactDetails.email: {
        if (event.target.value.length !== 0) {
          setIsEmailValidate(true);
        } else {
          setIsEmailValidate(false);
        }
      }
    }
  };
  useEffect(() => {
    if (isNameValidate && isEmailValidate) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [isNameValidate, isEmailValidate]);

  return (
    <>
    

      <div className={styles.contactFormWrapper}>
        <div className={styles.mainTitile}>
          <h2>{String.inquiry.title}</h2>
        </div>
        <div className={styles.leftRightWrapper}>
          <div className={styles.left}>
            <div className={styles.ImageWrapper}>
              <img src="./images/contactImage.jpg" alt="" />
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.inquiry}>
              <h2>{String.inquiry.subTitle}</h2>
              <p>{String.inquiry.des}</p>
            </div>
            <form
              name="/contact"
              method="POST"
              data-netlify="true"
              action="https://a-im.at/"
              className={styles.form}
            >
              <input type="hidden" name="form-name" value="contact" />
              <div className={styles.formFieldWrapper}>
                <p>
                  <label>
                    {formString.labelName}
                    <input
                      type="text"
                      name="name"
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        onChangeHandler(event, contactDetails.name)
                      }
                    />
                  </label>
                </p>
                <p>
                  <label>
                    {formString.labelEmail}
                    <input
                      type="email"
                      name="email"
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        onChangeHandler(event, contactDetails.email)
                      }
                    />
                  </label>
                </p>
                <p>
                  <label>
                    {formString.labelContacNumber}
                    <input type="text" name="Phone" />
                  </label>
                </p>
                <textarea name="message"></textarea>
              </div>
              <div>
                <p>Must be full the following</p>
                <p className={isNameValidate ? styles.green : styles.red}>
                  {formString.validationName}
                </p>
                <p className={isEmailValidate ? styles.green : styles.red}>
                  {formString.validationEmail}
                </p>
              </div>
              <div
                className={
                  isButtonDisabled
                    ? styles.buttonWrapper
                    : styles.isButtonDisabled
                }
              >
                <button type="submit">Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
