import React from "react";
import styles from "./CardWithImage.module.css";
import { Moduls } from "../Moduls/Moduls";

export const CardWithImage: React.FC<Moduls.CardWithImageProps> = (props) => {
  return (
    <section className={styles.cards}>
      <div
        className={`${styles.card} ${
          props.addClassOnScrollServicesWithImages
            ? "animate__animated animate__fadeInLeft"
            : ""
        }`}
      >
        <div className={styles.cardImage}>
          <img
            src={props.imagePath}
            style={{ height: "300px", width: "450px" }}
          />
        </div>
        <div className={styles.cardText}>
          <h2>{props.title}</h2>
          <p>{props.des}</p>
        </div>
        <div className={styles.cardInner}>
          <div>
            {props.isReadMoreButttonVisible && (
              <button>{props.readMoreButtonText}</button>
            )}
          </div>
          {/* <i className="fa fa-thumbs-o-up fa-lg" aria-hidden="true"></i> */}
        </div>
      </div>
    </section>
  );
};
